import { ReactStateDeclaration } from '@uirouter/react';
import {
  PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY,
  PERMISSION_CREATE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { AutoCreate } from '@/pages/coordinator/shift-planning-temps/components/form/auto-create';

import { ShiftPlanning } from './components/views';
import { Form } from './components/form';
import { BulkForm } from './components/form/bulk-edit';
import { BulkCreate } from './components/form/bulk-create';
import { BulkAssignColorsForm } from './components/form/bulk-assign-colors';

export const states: ReactStateDeclaration[] = [
  {
    name: 'base-layout.coordinator-temps-shift-planning',
    url: '/temps-shift-planning?{view}',
    component: ShiftPlanning,
    params: {
      view: { dynamic: true },
    },
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-temps-shift-planning.create',
    url: '/new',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-temps-shift-planning.edit',
    url: '/:shiftPlanningId/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-temps-shift-planning.bulk-create',
    url: '/bulk-create',
    component: BulkCreate,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-temps-shift-planning.bulk-edit',
    url: '/bulk-update',
    component: BulkForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-temps-shift-planning.bulk-assign-colors',
    url: '/bulk-assign-colors',
    component: BulkAssignColorsForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-temps-shift-planning.auto-create',
    url: '/auto-create',
    component: AutoCreate,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_CREATE],
      ],
    },
  },
];
