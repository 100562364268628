import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import moment from 'moment';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { authStore, buildingStore, shiftPlanningTempsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { DATE_WITHOUT_TIME_FORMAT, DATE_WITHOUT_TIME_FORMAT_ISO } from '@/utils/moment';
import { buildDropdownOptionsFromEnum, buildStoreOptions } from '@/utils/common';
import { SIDEBAR_WIDTH } from '@/constants';
import { startDateAfterNow } from '@/utils/form-rules';

import { disabledDate } from './setup';
import { GenerateEventsTemporaryVersion } from '@/types/enums';

const entity = 'coordinator-temps-shift-planning';
const store = shiftPlanningTempsStore;

const getFormFields = (hasAdminRole) => [
  {
    name: 'version',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.version',
      options: buildDropdownOptionsFromEnum(GenerateEventsTemporaryVersion, 'shiftPlanning.version.'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'buildingId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.building',
      options: buildStoreOptions(buildingStore, 'name'),
    },
    rules: [{ required: true }],
  },
  {
    name: 'dates',
    componentName: 'date-range-picker',
    params: {
      i18nLabel: 'shiftPlanning.eventRange',
      format: DATE_WITHOUT_TIME_FORMAT,
      disabledDate: !hasAdminRole && disabledDate,
    },
    rules: [!hasAdminRole && startDateAfterNow(), { required: true }].filter(Boolean),
  },
];

const onSubmit = ({ version, buildingId, dates }) => {
  const data = {
    version,
    buildingId,
    start: dates[0].format(DATE_WITHOUT_TIME_FORMAT_ISO),
    end: dates[1].format(DATE_WITHOUT_TIME_FORMAT_ISO),
  };

  return api.shiftPlanningTemps.autoGenerate(data).source.then(() => store.refresh());
}

export const AutoCreate = observer(({ transition }: UIViewInjectedProps) => {
  const { buildingId } = transition.targetState().params();

  const { loading } = useMassUpdateCashedStores([buildingStore]);

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = {
    version: GenerateEventsTemporaryVersion.MultiShiftsEventsPerDay,
    dates: [moment().add(1, 'M').startOf('month'), moment().add(1, 'M').endOf('month')],
    buildingId,
  };

  const title = getTranslatedString('shiftPlanning.auto-create-new');

  const formFields = getFormFields(authStore.hasAdminRole());

  const resourceController = withoutRepeatValue(initValues, {
    create: onSubmit,
  });

  return (
    <DrawerForm
      title={title}
      initValues={initValues}
      formFields={formFields}
      resourceController={resourceController}
      loaderCondition={loading}
      onClose={onBack}
      initialDisableSubmitValue={false}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
